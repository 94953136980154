.wizard {
  image-rendering: -webkit-optimize-contrast !important;
  image-rendering: pixelated !important;
  background-repeat: no-repeat;
  margin-bottom: -22px;
}

.wizard_scale {
  transform: scale(0.5);
}

.wizard_flip_horizontal_scale {
  transform: scale(0.5) scaleX(-1);
}

.wizard_box {
  height: 45px;
  width: 100%;
  margin: 0;
  position: relative;
}

/* 1_idle */

.wizard_1_1 {
  width: 46px;
  height: 56px;
  background: url(/src/assets/imgs/wizards/1_idle.png) no-repeat -48px -73px;
}

.wizard_1_2 {
  width: 46px;
  height: 56px;
  background: url(/src/assets/imgs/wizards/1_idle.png) no-repeat -177px -73px;
}

.wizard_1_3 {
  width: 46px;
  height: 57px;
  background: url(/src/assets/imgs/wizards/1_idle.png) no-repeat -306px -72px;
}

.wizard_1_4 {
  background: url(/src/assets/imgs/wizards/1_idle.png) no-repeat -48px -220px;
  width: 46px;
  height: 57px;
}

.wizard_1_5 {
  background: url(/src/assets/imgs/wizards/1_idle.png) no-repeat -177px -221px;
  width: 46px;
  height: 56px;
}

/* 2_walk */

.wizard_2_1 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -47px -72px;
  width: 46px;
  height: 57px;
}

.wizard_2_2 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -174px -74px;
  width: 49px;
  height: 55px;
}

.wizard_2_3 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -304px -74px;
  width: 47px;
  height: 55px;
}

.wizard_2_4 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -47px -220px;
  width: 45px;
  height: 57px;
}

.wizard_2_5 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -174px -222px;
  width: 49px;
  height: 55px;
}

.wizard_2_6 {
  background: url(/src/assets/imgs/wizards/1_walk.png) no-repeat -304px -222px;
  width: 46px;
  height: 55px;
}

/* 3_attack */

.wizard_3_1 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -52px -76px;
  width: 53px;
  height: 53px;
}

.wizard_3_2 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -181px -75px;
  width: 51px;
  height: 54px;
}

.wizard_3_3 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -309px -72px;
  width: 48px;
  height: 57px;
}

.wizard_3_4 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -437px -75px;
  width: 51px;
  height: 54px;
}

.wizard_3_5 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -51px -219px;
  width: 48px;
  height: 58px;
}

.wizard_3_6 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -176px -224px;
  width: 64px;
  height: 53px;
}

.wizard_3_7 {
  background: url(/src/assets/imgs/wizards/1_attack.png) no-repeat -307px -224px;
  width: 61px;
  height: 53px;
}

/* 4_skill_shield */

.wizard_4_1 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -47px -71px;
  width: 49px;
  height: 58px;
}

.wizard_4_2 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -176px -67px;
  width: 49px;
  height: 62px;
}

.wizard_4_3 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -287px -69px;
  width: 79px;
  height: 61px;
}

.wizard_4_4 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -414px -68px;
  width: 83px;
  height: 61px;
}

.wizard_4_5 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -25px -213px;
  width: 87px;
  height: 65px;
}

.wizard_4_6 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -155px -214px;
  width: 85px;
  height: 64px;
}

.wizard_4_7 {
  background: url(/src/assets/imgs/wizards/1_skill_shield.png) no-repeat -285px -215px;
  width: 83px;
  height: 63px;
}

/* backgrounds */

/* 1_idle */

.wizard_1_1_bg {
  background-image: url(/src/assets/imgs/wizards/1_idle.png);
}

.wizard_1_2_bg {
  background-image: url(/src/assets/imgs/wizards/1_walk.png);
}

.wizard_1_3_bg {
  background-image: url(/src/assets/imgs/wizards/1_attack.png);
}

.wizard_1_4_bg {
  background-image: url(/src/assets/imgs/wizards/1_skill_shield.png);
}
