:root {
  --yellow-color: #f8cb21;
  --dark-bg-color: #1b1b1b;
  --grey-dark-color: #1b1b1b;
}

html {
  touch-action: pan-x pan-y;
}

html body h1,
html body h2 {
  font-size: 26px;
}

html body p {
  font-size: 18px;
}

.Typewriter span {
  line-height: 1.8 !important;
}

.d_flex_column_1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.relative {
  position: relative;
}

.jarvis_alert {
  font-weight: bolder;
}

.jarvis_alert_big {
  font-size: 3em;
}

.jarvis_alert_middle {
  font-size: 2em;
}

.m_left_10 {
  margin-left: 10px !important;
}

.font_size_20 {
  font-size: 20px !important;
}

.opacity_text_read {
  opacity: 0.5;
}

.default_font_family {
  font-family: "Fira Mono", sans-serif !important;
}

.open_sans_font_family {
  font-family: "Open Sans", sans-serif !important;
}

.pixelify_font_family {
  font-family: "Pixelify Sans", sans-serif !important;
}

.vt323_font_family {
  font-family: "VT323", monospace !important;
}

.nerdfy_challenge_blink {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  display: none;
  z-index: 101;
}

.nerdfy_notifiers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

/* COLORS BRAND */
.nerdX_color {
  color: #015289 !important;
}

.nerdbook_color {
  color: #3c5a98 !important;
}

.nerdflix_color {
  color: #df0912 !important;
}

.nerdsoft_color {
  color: #fff !important;
}

.nerdtel_color {
  color: #03c7fd !important;
}

/* COLORS NERDFY */

.nerdfy_grey_stronger {
  color: #393939;
}

.nerdfy_purple {
  color: #9d6fb0;
}

.nerdfy_red {
  color: #f14c5d;
}

.nerdfy_blue {
  color: #3399ff;
}

.nerdfy_green {
  color: #2dd25c;
}

.nerdfy_orange {
  color: #e37a3e;
}

.nerdfy_yellow {
  color: #f8cb21;
}

.nerdfy_white {
  color: #fff;
}

.nerdfy_acai {
  color: #e31986;
}

.nerdfy_navy {
  color: #1d5ae5;
}

.nerdfy_wine {
  color: #9d1d11;
}

.nerdfy_gold {
  color: #a68854;
}

.nerdfy_pink {
  color: #d16b9a;
}

.nerdfy_brown {
  color: #956840;
}

.nerdfy_grey_middle {
  color: #707070;
}

.nerdfy_grey_strong {
  color: #636363;
}

.nerdfy_grey_stronger {
  color: #393939;
}

.nerdfy_bg_purple {
  background: #9d6fb0;
}

.nerdfy_bg_red {
  background: #f14c5d;
}

.nerdfy_bg_blue {
  background: #3399ff;
}

.nerdfy_bg_green {
  background: #2dd25c;
}

.nerdfy_bg_orange {
  background: #e37a3e;
}

.nerdfy_bg_yellow {
  background: #f8d255;
}

.nerdfy_bg_white {
  background: #fff;
}

.nerdfy_bg_acai {
  background: #e31986;
}

.nerdfy_bg_navy {
  background: #1d5ae5;
}

.nerdfy_bg_wine {
  background: #9d1d11;
}

.nerdfy_bg_pink {
  background: #d16b9a;
}

/* LEVEL ICON */

.nerdfy_level_icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.nerdfy_level_icon_not_active {
  opacity: 0.1;
}

/* LEVEL ICON END */

/* COLORS NERDFY END */

.swiper {
  display: flex;
  height: 100%;
  width: 100%;
  background: #181618;
}

.swiper-slide {
  height: 100%;
  width: 100%;
}

.swiper-slide .slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.d_none {
  display: none !important;
}

.display_none {
  display: none;
}

.absolute {
  position: absolute;
}

.alert_nerdfy {
  position: absolute;
  z-index: 9998;
  font-size: 18px;
  border-radius: 10px;
  padding: 10px;

  touch-action: none;
  user-select: none;
  overflow: hidden;
}

.notify_nerdfy {
  margin: 10px 50px;
  background: #252627;
  font-size: 18px;
  border-radius: 10px;
  padding: 10px;
  opacity: 0.6 !important;
}

.separator {
  color: #252627;
  margin: 0 8px;
}

.min_separator {
  color: #252627;
  margin: 0 5px;
}

.filter_yellow {
  filter: invert(83%) sepia(99%) saturate(5368%) hue-rotate(317deg)
    brightness(106%) contrast(95%);
}

.filter_green {
  filter: invert(74%) sepia(9%) saturate(1775%) hue-rotate(39deg)
    brightness(92%) contrast(91%);
}

.filter_purple {
  filter: invert(59%) sepia(8%) saturate(2528%) hue-rotate(238deg)
    brightness(82%) contrast(77%);
}

.filter_orange {
  filter: invert(66%) sepia(69%) saturate(2983%) hue-rotate(335deg)
    brightness(95%) contrast(86%);
}

.filter_blue {
  filter: invert(64%) sepia(37%) saturate(7500%) hue-rotate(191deg)
    brightness(102%) contrast(104%);
}

/* NERDFY LOGO */

.bg_nerdfy_gradient {
  background: linear-gradient(to right, #f14c5d, #2dd25c, #3399ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg_nerdfy_gradient_mono {
  background: linear-gradient(
    to right,
    rgba(99, 99, 99, 1) 49%,
    rgba(57, 57, 57, 1) 92%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1.nerdfy_logo_brand {
  font-family: "Bebas Neue", sans-serif;
  font-size: 35px;
  width: 90px;
  margin: 0;
  padding: 0;
}

/* NERDFY LOGO END */

.primary_button {
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
}

.safe--area {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  --ion-safe-area-top: 10px;
  --ion-safe-area-bottom: 10px;
  width: 100%;
}

ion-popover {
  --background: #181618;
}

.avatar {
  position: relative;
  width: 30px;
  height: 30px;
}

.avatar .img {
  width: 60px;
  height: 60px;
  transform: scale(0.5);
}

.nerdfy_icon {
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

/* COLORS */

.green_opacity {
  color: #465231;
}

.m_left_10 {
  margin-left: 10px;
}

/* spinner */
.spinner > .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background-color: #ffde46;
  opacity: 0.4;
}

.spinner > .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background-color: #ffde46;
  opacity: 0.6;
}

.spinner > .bounce3 {
  background-color: #ffde46;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.battle_icon_cmd {
  font-size: 1.6em !important;
}

/* jarvis bubble */
.nerdfy_bubble {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  background: var(--grey-dark-color);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9500;
  line-height: 28px;
  opacity: 0.9;
}

.nerdfy_bubble > .bubble_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0 10px;
}

.nerdfy_bubble > .bubble_header > h2 {
  font-size: 1.3em;
  padding: 0;
  margin: 0;
}

.nerdfy_bubble > ul {
  all: unset;
}

.nerdfy_bubble > ul li {
  all: unset;
}

.nerdfy_bubble > .bubble_content {
  flex: 1;
  text-align: left;
  overflow: auto;
  padding: 10px;
  color: #979ca6;
}

.nerdfy_bubble > .bubble_content {
  margin: 0 !important;
  padding: 15px 10px !important;
  font-size: 1.1em;
  line-height: 25px;
}

.nerdfy_bubble .jarvis_question_bubble_option {
  padding: 15px;
}

.nerdfy_bubble > .bubble_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 65px;
  padding: 0 15px;
  border-top: 5px solid var(--grey-dark-color);
}

.nerdfy_bubble > .bubble_header .close_button {
  margin-bottom: 3px;
}

.nerdfy_bubble > .bubble_footer .icon_wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  opacity: 0.3;
  font-size: 20px;
}

.nerdfy_bubble > .bubble_footer .icon_wrapper span {
  font-size: 25px;
}

.nerdfy_bubble > .bubble_footer .footer_title {
  font-size: 1.2em;
}

.nerdfy_bubble > .bubble_footer .icon_wrapper svg {
  width: 22px;
}

.nerdfy_bubble > .bubble_footer .feedback_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.nerdfy_bubble > .bubble_footer .f_button_down svg {
  color: #f14c5d;
}

.nerdfy_bubble > .bubble_footer .f_button_up svg {
  color: #2dd25c;
}

.nerdfy_bubble > .bubble_question_subheader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0 0 0 15px;
  opacity: 0.5;
  font-size: 1em;
}

/* AVATARS NERD */

.nerdfy_avatar {
  width: 22px;
  height: 28px;
  transform: scale(1.5);
  image-rendering: -webkit-optimize-contrast !important;
  image-rendering: pixelated !important;
  background-repeat: no-repeat;
  background-size: contain;
}

/* AVATARS NERD END */
